exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-alojamiento-fotos-index-tsx": () => import("./../../../src/pages/alojamiento/fotos/index.tsx" /* webpackChunkName: "component---src-pages-alojamiento-fotos-index-tsx" */),
  "component---src-pages-alojamiento-index-tsx": () => import("./../../../src/pages/alojamiento/index.tsx" /* webpackChunkName: "component---src-pages-alojamiento-index-tsx" */),
  "component---src-pages-blog-tsx": () => import("./../../../src/pages/blog.tsx" /* webpackChunkName: "component---src-pages-blog-tsx" */),
  "component---src-pages-deportes-extremos-index-tsx": () => import("./../../../src/pages/deportes-extremos/index.tsx" /* webpackChunkName: "component---src-pages-deportes-extremos-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-mapa-full-screen-tsx": () => import("./../../../src/pages/mapa-full-screen.tsx" /* webpackChunkName: "component---src-pages-mapa-full-screen-tsx" */),
  "component---src-pages-sitemap-tsx": () => import("./../../../src/pages/sitemap.tsx" /* webpackChunkName: "component---src-pages-sitemap-tsx" */),
  "component---src-templates-blog-post-tsx": () => import("./../../../src/templates/blog-post.tsx" /* webpackChunkName: "component---src-templates-blog-post-tsx" */),
  "component---src-templates-category-tsx": () => import("./../../../src/templates/category.tsx" /* webpackChunkName: "component---src-templates-category-tsx" */),
  "component---src-templates-extreme-sport-template-tsx": () => import("./../../../src/templates/extreme-sport-template.tsx" /* webpackChunkName: "component---src-templates-extreme-sport-template-tsx" */),
  "component---src-templates-photos-tsx": () => import("./../../../src/templates/photos.tsx" /* webpackChunkName: "component---src-templates-photos-tsx" */),
  "component---src-templates-single-page-tsx": () => import("./../../../src/templates/single-page.tsx" /* webpackChunkName: "component---src-templates-single-page-tsx" */)
}

